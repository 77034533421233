@import url('../fonts/museo_sans.css');
@import url('../fonts/lobster.css');

$c_orang: #8a53b1;
$c_darck: #333333;
$c_gray: #707070;

html, body {
  font-family: "Museo Sans Cyrl", sans-serif;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100%;

  @include sm {
    font-size: 12px;
  }
  @include md {
    font-size: 14px;
  }
}

.header {
  flex: 0 0 auto;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.footer {
  flex: 0 0 auto;
}

.f-grow {
  flex: 1 1 auto;
}

.inline-svg {
  width: 40px;
  height: 40px;
  background: $c_orang;
  border-radius: 0.6rem;
  display: block;
  padding: 0 2px 5px 0;
  @include transition();
}

.collapsed {
  .inline-svg {
    padding: 0 0 5px 3px;
    &:hover {
      padding: 0 2px 5px 0;
    }
  }
}

.svg-menu-toggle {
  fill: #fff;
  pointer-events: all; //needs to be there so the hover works
  cursor: pointer;
  .bar {
    transform: rotate(0) translateY(0) translateX(0);
    opacity: 1;
    transform-origin: 20px 10px;
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
  }
  .bar:nth-of-type(1) {
    transform-origin: 20px 10px;
    transform: rotate(-45deg) translateY(0) translateX(0);
  }
  .bar:nth-of-type(2) {
    opacity: 0;
  }
  .bar:nth-of-type(3) {
    transform-origin: 20px 20px;
    transform: rotate(45deg) translateY(0em) translateX(0em);
  }
}

.collapsed {

  .svg-menu-toggle {

    .bar:nth-of-type(1) {
      transform: rotate(0) translateY(0) translateX(0);
    }
    .bar:nth-of-type(2) {
      opacity: 1;
    }
    .bar:nth-of-type(3) {
      transform: rotate(0) translateY(0em) translateX(0em);
    }
  }

  .svg-menu-toggle:hover {
    .bar:nth-of-type(1) {
      transform: rotate(-45deg) translateY(0) translateX(0);
    }
    .bar:nth-of-type(2) {
      opacity: 0;
    }
    .bar:nth-of-type(3) {
      transform: rotate(45deg) translateY(0em) translateX(0em);
    }
  }

}

.title {
  color: #333;
  font-family: 'Lobster', sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 2.25rem;
  position: relative;
  padding-bottom: 20px;
  small {
    display: block;
    color: #a1a1a1;
    font-size: 0.875rem;
    font-weight: 400;
  }
  &:after {
    content: '';
    width: 300px;
    max-width: 100%;
    background-color: $c_orang;
    height: 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &_big {
    font-size: 3.75rem;
    &:after {
      display: none;
    }
    @include sm {
      font-size: 2.75rem;
    }
  }
  &_center {
    text-align: center;
    &:after {
      margin: auto;
    }
  }
  &_mb {
    margin-bottom: 20px;
  }
}

.main-btn {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  font-size: rem(14px);
  font-weight: 500;
  text-transform: uppercase;
  border-radius: rem(20px);;
  border: 1px solid $c_orang;
  background-color: $c_orang;
  outline: none !important;
  @include transition();

  &, &:visited, &:focus {
    color: #fff;
    text-decoration: none;
  }
  &:hover, &:active {
    color: $c_orang;
    background: transparent;

    text-decoration: none;

  }

  &_invers {
    &, &:visited, &:focus {
      color: $c_orang;
      background: transparent;
      text-decoration: none;
    }
    &:hover, &:active {
      color: #fff;
      background-color: $c_orang;
      text-decoration: none;

    }
  }
  &_light {
    background-color: #fff;
    &, &:visited, &:focus {
      color: #333;
    }
    &:hover, &:active {
      color: #fff;
    }
  }
}

.section {
  padding: 40px 0;
}

.text {
  color: #333;
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;
  line-height: 1rem;
  @include sm {
    margin-bottom: 20px;
  }
}

.form {

  &__inp {
    outline: none !important;
    border: 1px solid $c_orang;
    display: block;
    width: 100%;
    padding: 5px 10px;
    background-color: #fff;
    margin-bottom: 30px;
    color: #333;
    font-size: 1.125rem;
    font-weight: 400;
  }
}

img {
  max-width: 100%;
}

.close {
  z-index: 100;
  display: block;
  position: relative;
}

a {
  outline: none !important;
}