.slider {
  position: relative;
  padding: 40px 0 10px;
  background-image: url("../img/headeimg.png");
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  @include md {
    background-image: none;
  }
  &__descr {
    color: #333;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 35px;
  }
  &__bsm {
    position: absolute;
    bottom: -80px;
    right: 0;

    @include sm {
      position: relative;
      bottom: 0;
      width: 50%;
      top: 10px;
      margin: 0 0 0 auto;
      display: block;
    }

  }
}

.boximg {
  background-image: url('../img/block2_bg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  margin-bottom: 30px;
  @include md {
    background-image: none;
  }
}

.boxefect {
  background-image: url('../img/bgbox_effect.png');
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  background-repeat: no-repeat;

  margin-bottom: 30px;
  @include md {
    background-image: none;
  }
}

.s-box {

  margin-bottom: 20px;

  &__img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
  }
  &__title {
    color: #333;
    font-family: 'Lobster', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 15px;
  }
  &__descr {
    color: #333;
    font-size: 0.875rem;
    font-weight: 300;
  }
}

.prise {
  margin-bottom: 30px;

  @include transition();
  &:hover {
    transform: scale(1.1);

  }

  &__title {
    // Style for "Прямоуголь"
    background-color: $c_orang;
    text-align: center;
    padding: 35px 0 25px;
    color: #fff;
    font-family: 'Lobster', sans-serif;
    font-size: 3rem;
    font-weight: 400;
  }
  &__count {

    text-align: center;
    color: #333;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 900;
    padding: 25px 0;
    border-bottom: 1px solid #dfdfdf;
    span {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
  &__descr {
    padding: 30px 25px;
    color: #333;
    font-size: 1.125rem;
    font-weight: 500;
  }
  &__pressent {
    text-align: center;
    padding: 10px 0;

  }
  &__img {
    max-width: 100%;
    display: inline-block;
    margin: auto;
  }
  &__prise {
    margin-bottom: 20px;
    text-align: center;
    padding: 30px 0;
    background-color: #4a2b5f;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    span {
      font-size: 2.375rem;
      font-weight: 500;
    }
  }
  &__btn {

  }
}

.effect {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @include sm {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__imgwrapp {
    flex: 0 0 auto;
    padding-right: 30px;
    @include sm {
      padding-right: 0;
    }

  }
  &__img {
    width: 55px;
  }
  &__count {
    flex: 0 0 auto;
    width: 80px;
    text-align: center;
    color: #8f8f8f;
    font-family: 'Lobster', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    span {
      display: block;
      font-size: 4.5rem;
      line-height: 3rem;
    }

  }
  &__text {
    padding-left: 30px;
    color: #333;
    font-size: 0.875rem;
    font-weight: 300;
  }
  &__smaill {
    color: #333;
    font-size: 0.75rem;
    font-weight: 300;
  }
}

.comment {
  padding: 0 40px;
  @include sm {
    padding: 0;
  }
  &__img {
    width: 100%;
    margin-bottom: 35px;
  }
  &__text {
    position: relative;
    padding: 10px 15px 40px 45px;
    color: #333;
    font-size: 1.125rem;
    font-weight: 400;
    &:before {
      content: '';
      display: block;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAXCAYAAACFxybfAAACZUlEQVRIS53W28tOeRQH8M/rFMY45FAGCSXnyaGYcRg1JBISQnIl/5reSxoX5EKSN4cLM0LcoKHGIecRwuj7th899uz9PPt5Vu3a7d/6rv1d67dOA5rJj5iHmZiCcbiGG83g37QGMBtzMB2TMCYfO8kMrC5AZb2hHkiMxDIsx/iyoToSo/ELFnVg2JREovcbJtbZqiIxATswuUuUmpCI93GmY8TLhyGwGz9UEPiMB/gHL/EC/3Yg+jPW1py/Kmw9w7t2EqOwtyIC+flfxfOhYSIuwO8Vus9xGX+3n7WT2FSRA29wBgE3lURzX7K+BLiJXOGXusRMFewpHb7FyS4hryK2BfNLB9dxpVtibq8owydIJHLvj4rnf16UDKeH7C99C+Y+Pha5lPfX5etI+A43iHWAF/Gwg+56LG1g6y4uFcSGS2cF1jUARuU/XMCdGv0jVc2oRjcVdgrvQ2Ib5jYkEbWEN+BcV7ukrxzowU5Uc82nQ+IQMht6kcdF0rZjMlu29mKk0D0bEscwog/wINJ0WpLu+Gsfdu6HxPE+gIGcRxKsJauwpg9bwznRL4nUfeq/Jb0k+HdcQ+IoxvbhQcr1VhtuITb3YedTSOzET32AUyEZZi2ZVsyeXk09Dokl2NAjMi39RNE32qEHO+0NNf8YColURuq7dumoAJeTsqVSNz3rfMxIGGxN0anYhWxU3SQVERJ1shGLuxnBJ/yRptc+ykMkEzDLZ5WkZf+JqxXXUNZPqa7ssFFlMJ7D0wDLm1WuJlme7peJmEXnXdFebxdTsIGTwypxJhGZVWxq8Tzz4l4xe75N5K/M0nLUODzecQAAAABJRU5ErkJggg==");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 33px;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &_light {
      font-weight: 100;
      line-height: 1.5rem;
      padding: 10px 15px 20px 75px;
      &:before {
        width: 70px;
        height: 30px;
      }
    }
  }
}

.consul {
  margin-top: 50px;
  position: relative;

  background-image: url('../img/shutterstock-194848103.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 50px;

  &__img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    width: 130px;
    height: 130px;
    top: -65px;
  }
  &__title {
    color: #fff;
    font-family: 'Lobster', sans-serif;
    font-size: 2.25rem;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__descr {
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.anser {
  padding: 30px 0;
  background-image: url('../img/anser.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__title {
    color: #fff;
    font-family: 'Lobster', sans-serif;
    font-size: 2.25rem;
    font-weight: 400;
    bottom: 20px;
  }
}

.contact {
  &__box {
    background-color: $c_orang;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  &__img {
    width: 15px;
    display: inline-block;
    position: absolute;

    top: 0;
    left: 0;
  }
  &__row {
    position: relative;
    padding-left: 30px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 300;
    b {
      font-weight: 700;
    }
  }
  &__map {
    min-height: 300px;
    background-size: cover;
    background-image: url('../img/map.png');
    background-position: center;
    background-repeat: no-repeat;
  }
}
.otziv {
  padding: 0 45px;
  img {
    width: 100%;
  }

  .js-btn {
    top: 15%;
    background: none;
    width: 20px;
  }
  @include sm {
    padding: 0;
    .js-btn {
      top: 25%;
    }
  }
}
.doposle {
  img {
    width: 100%;
    margin: 0 10px;
  }
  .js-btn {
    background: none;
    width: 20px;
  }
}

.roundimg {
  width: 100%;
  border-radius: 50%;
  border: 10px solid #fff;
}