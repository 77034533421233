.header {
  padding: 20px 0;
  &__content {
    background-color: #ebebeb;
  }
  &__row {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #333;
  }

  &__logo {
    margin: 10px;
    max-width: 100%;
  }
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    li {
      text-align: center;
      flex: 1 1 auto;
      position: relative;

      &:last-of-type {
        &:after {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          background: #fff;
        }
      }
    }
  }

  .nav {
    overflow: hidden;
    padding: 0 0;
  }
  .navbar {
    padding: 0;
  }
  .navbar-toggler {
    padding: 0;
    outline: none !important;
  }
}

.nav {
  background-color: transparent;

  width: 100%;
  display: block;

  &_fix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ebebeb;
    z-index: 10;
  }

  &__list {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    @include md {
      padding-top: 20px;
      flex-direction: column;
    }
  }
  &__item {
    text-align: center;
    position: relative;
    flex: 1 1 auto;
    padding: 0 15px;

  }
  &__link {
    display: inline-block;
    position: relative;
    text-transform: uppercase;

    color: #333;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 20px 0;

    @include sm {
      padding: 7px 0;
    }

    &:after {
      height: 3px;
      background: $c_orang;
      content: '';
      width: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      @include transition();
    }
    &, &:visited, &:focus {
      color: #333;
      text-decoration: none;
    }
    &:hover, &:active {
      color: $c_orang;
      text-decoration: none;
      &:after {
        width: 90%;
      }
    }
  }
}

.seolist {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  li {
    padding: 5px;
    a {
      &, &:visited, &:focus {
        opacity: 1;
        @include transition();
        text-decoration: none;
      }
      &:hover, &:active {
        opacity: .6;
        text-decoration: none;
      }
    }
  }
}

.call {
  font-size: 0.875rem;
  font-weight: 700;
  &, &:visited, &:focus {
    color: #fff;
    text-decoration: underline;
    text-transform: uppercase;
  }
  &:hover, &:active {

    color: #fff;
    text-decoration: none;
  }

}