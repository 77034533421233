.footer {
  padding: 10px 20px;
  // Style for "Rectangle"
  box-shadow: -0.187rem 0 0.625rem rgba(0, 0, 0, 0.09);
  background-color: #8f8f8f;
  opacity: 0.6;
  text-align: center;
  font-size: 0.875rem;
  color: #333;
  font-weight: 300;

  a {
    &, &:visited, &:focus {
      color: #333;
      font-weight: 900;
      text-decoration: none;
      @include transition();
    }
    &:hover, &:active {
      color: #555;
      text-decoration: none;
    }
  }
}